import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


function OverUnderTricks({ query, isLoading }) {
    const [declarerData, setDeclarerData] = useState([]);
    const [defenderData, setDefenderData] = useState([]);

    useEffect(() => {
        if (!isLoading && query && query.over_under_play) {
            const { declarer, defender } = query.over_under_play;

            // Assuming 'results' is an array of numbers
            setDeclarerData(declarer.results);
            setDefenderData(defender.results);
        }
    }, [query, isLoading]);

    const createHistogramData = (data) => {
        // Count the occurrences of each number
        const counts = data.reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1;
            return acc;
        }, {});

        const sortedLabels = Object.keys(counts).sort((a, b) => a - b);


        // Convert to Chart.js data format
        return {
            labels: sortedLabels,
            datasets: [{
                label: 'Counts',
                data: sortedLabels.map(label => counts[label]),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                barThickness: 15,
            }],
        };
    };
    const chartOptions = (title) => ({
       plugins: {
           title: {
               display: true,
               text: title,
               position: 'top',
           },
           legend: {
               display: false,
           }
       },
    });


    if (isLoading) {
        return (
            <div className="over-under-tricks" key={isLoading ? 'loading' : 'loaded'}>
                {isLoading ?
                    <div className="loader"></div> :  // Use the loader class for the spinning animation
                    null
                }
            </div>
        );
    }
    else {
        const declarerHistogramData = createHistogramData(declarerData, 'Declarer Over/Under Tricks');
        const defenderHistogramData = createHistogramData(defenderData, 'Defender Over/Under Tricks');

        return (
            <div className="over-under-tricks-container">
                <div className="histogram">
                    <Bar data={declarerHistogramData} options={chartOptions('Declarer Over/Under Tricks')} />
                </div>
                <div className="histogram">
                    <Bar data={defenderHistogramData} options={chartOptions('Defender Over/Under Tricks')} />
                </div>
            </div>
        );

    }
}

export default OverUnderTricks;