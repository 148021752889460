import React, {useCallback, useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';


const ContractBidPerformance = ({ isLoading, biddata }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Average Bid Accuracy',
                data: [],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    });

    const mapContractType = (type) => {
        const mappings = {
            'H': 'Hearts',
            'S': 'Spades',
            'D': 'Diamonds',
            'C': 'Clubs',
            'NT': 'No Trump',
            'PASS': 'PASS' // Maintain 'PASS' as is
        };
        return mappings[type] || type; // Return the full name or the original type if not found
    };

    const processData = useCallback((data) => {
        let processedData = {};

        // Iterate over each item in biddata array
        data.forEach(item => {
            // Now iterate over each bid in the bid_data array of the item
            item.bid_data.forEach((bid) => {
                if (bid.direction.includes(bid.declarer)) {
                    let contractType = bid.contract === "PASS" ? "PASS" : bid.contract.split(' ')[1];
                    if (!processedData[contractType]) {
                        processedData[contractType] = {
                            total: 0,
                            count: 0
                        };
                    }
                    if (bid.bid_accuracy !== null) {
                        processedData[contractType].total += parseFloat(bid.bid_accuracy);
                        processedData[contractType].count += 1;
                    }
                }
            });
        });

        return Object.keys(processedData).map((key) => ({
            contractType: mapContractType(key),
            avgAccuracy: processedData[key].count > 0 ? processedData[key].total / processedData[key].count : 0
        }));
    }, []);

    useEffect(() => {
        if (biddata) {
            const processed = processData(biddata);
            setChartData({
                labels: processed.map((data) => data.contractType),
                datasets: [
                    {
                        label: 'Average Bid Accuracy by Contract Type',
                        data: processed.map((data) => data.avgAccuracy),
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    },
                ],

            });
        }
    }, [biddata, processData]); // This will trigger the effect whenever apiData changes

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="contractype-bid-chart">
            {isLoading ?
                <div className="loader-container"> {/* Use the loader-container class here */}
                    <div className="loader"></div> {/* Your loader */}
                </div> :
                <Bar data={chartData} options={options}/> /* Chart displayed when not loading */
            }
        </div>
    );
};

export default ContractBidPerformance;
