import React, { useState, useEffect } from 'react';
import FrontPage from "./FrontPage";
import BidTab from "./BidTab";
import axios from "axios";
import { API_BASE_URL} from "../config";

function MainBody( {acblValue } ) {
  const [activeTab, setActiveTab] = useState('FrontPage');
  const [timeFrame, setTimeFrame] = useState(730);
  const [partners, setPartners] = useState([]);
  const [partnerAcbl, setPartnerAcbl] = useState(null);


  const timeFrameMapping = {
      'full history': null,
      '5y': 1825,
      '2y': 730,
      '1y': 365,
      '6mo': 182,
      '3mo': 91,
      'last month': 30,
      'last week': 7
  };

  const handleTimeFrameChange = (newTimeFrameKey) => {
      setTimeFrame(timeFrameMapping[newTimeFrameKey]);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const isTimeActive = (frame) => timeFrame === timeFrameMapping[frame];
  const isTabActive = (tabName) => activeTab === tabName;

  const renderActiveTabContent = () => {
      switch(activeTab) {
          case 'FrontPage':
              return <FrontPage timeFrame={timeFrame} acblValue={acblValue} partnerAcbl={partnerAcbl} />;
          case 'bid':
              return <BidTab timeFrame={timeFrame} acblValue={acblValue} partnerAcbl={partnerAcbl} />;
          case 'play':
              return <tab2 />;
          // ... other cases ...
          default:
              return null;
        }
    };
    useEffect(() => {
        let url = `${API_BASE_URL}/api/partners/${acblValue}/`;
        if (timeFrame !== null) {
            url += `?time_delta=${timeFrame}`;
        }
        axios.get(url, { withCredentials: true })

            .then(response => {
                setPartners(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [acblValue, timeFrame]);

    const handlePartnerChange = (e) => {
        const value = e.target.value;
        setPartnerAcbl(value === 'null' ? null : value);
    };


  return (
      <div className="main-body-container">

      <div>
        <div className="tab-menu">
            <div className="dropdown-container">
                <select id="partner-select" className="dropdown-select" onChange={handlePartnerChange}>
                    <option value="null">All Partners</option>
                    {partners.map(partner => (
                        <option key={partner.partner_acbl} value={partner.partner_acbl}>
                            {partner.partner_name}
                        </option>
                    ))}
                </select>
            </div>
          <button className={isTabActive('FrontPage') ? 'active-tab' : 'tab' }
              onClick={() => handleTabClick('FrontPage')}>Main Dashboard</button>
          <button className={isTabActive('bid') ? 'active-tab' : 'tab' }
               onClick={() => handleTabClick('bid')}>Bid</button>
          <button className={isTabActive('play') ? 'active-tab' : 'tab' }
                  onClick={() => handleTabClick('tab3')}>Tab 3</button>
        </div>
          <div className="time-frame-buttons">
              {/* Time frame buttons */}
              {Object.keys(timeFrameMapping).map(key => (
                  <button key={key} onClick={() => handleTimeFrameChange(key)}
                  className={isTimeActive(key) ? 'time-button-active': 'time-button'}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
              ))}
          </div>
        <div className="tab-content">
            {renderActiveTabContent()}
        </div>
      </div>
      </div>
  );
}

export default MainBody;