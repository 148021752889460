import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const GameTypeBidPerformance = ({ isLoading, biddata }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Average Bid Accuracy by Game Type',
                data: [],
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    });

    const processData = useCallback((data) => {
        let processedData = {};

        data.forEach(item => {
            item.bid_data.forEach((bid) => {
                if (bid.direction.includes(bid.declarer) && bid.declarer_max_game_type) {
                    let gameType = bid.declarer_max_game_type;
                    if (!processedData[gameType]) {
                        processedData[gameType] = {
                            total: 0,
                            count: 0
                        };
                    }
                    if (bid.bid_accuracy !== null) {
                        processedData[gameType].total += parseFloat(bid.bid_accuracy);
                        processedData[gameType].count += 1;
                    }
                }
            });
        });

        return Object.keys(processedData).map((key) => ({
            gameType: key,
            avgAccuracy: processedData[key].count > 0 ? processedData[key].total / processedData[key].count : 0
        }));
    }, []);

    useEffect(() => {
        if (biddata) {
            const processed = processData(biddata);
            setChartData({
                labels: processed.map((data) => data.gameType),
                datasets: [
                    {
                        label: 'Average Bid Accuracy by Game Type',
                        data: processed.map((data) => data.avgAccuracy),
                        backgroundColor: 'rgba(153, 102, 255, 0.5)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    },
                ],
            });
        }
    }, [biddata, processData]);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="gametype-bid-chart">
            {isLoading ?
                <div className="loader-container">
                    <div className="loader"></div>
                </div> :
                <Bar data={chartData} options={options}/>
            }
        </div>
    );
};

export default GameTypeBidPerformance;
