import React, { useState, useCallback } from 'react';
import Header from './components/Header';
import MainBody from './components/MainBody';
import LandingPage from './components/LandingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccountActivation from './AccountActivation';
import './App.css';



function App() {
    const [acblValue, setAcblValue] = useState('');
    const handleAcblValueChange = useCallback((value) => {
        setAcblValue(value);
    }, []);

    return (
        <Router>
            <div className="App">
                <Header onAcblValueChange={handleAcblValueChange}/>
                <Routes>
                    <Route path="/account/activate" element={<AccountActivation />} />
                    <Route path="/" element={acblValue === '' ? <LandingPage /> : <MainBody acblValue={acblValue}/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
