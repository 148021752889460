import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
import {CategoryScale} from "chart.js";
import axios from "axios";
import { API_BASE_URL} from "../../config";

Chart.register(CategoryScale);

function ContractPerformance({ days, acbl, partAcbl}) {
    const [contractData, setContractData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Function to handle time frame changes

    useEffect(() => {
        setIsLoading(true);
        let url = `${API_BASE_URL}/api/total/contract/${acbl}/`;
        if (partAcbl !== null) {
            url += `${partAcbl}/`;
        }
        if (days !== null) {
            url += `?time_delta=${days}`;
        }
        axios.get(url, {withCredentials: true})
            .then(response => {
                setContractData(response.data.contract_performance);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                setIsLoading(false);
            });

    }, [days, acbl, partAcbl]); // Dependency array includes timeFrame


    const chartData = contractData ? {
        labels: Object.keys(contractData.bid),
        datasets: [{
            data: Object.values(contractData.bid),
            backgroundColor: [
                // Define different colors for each segment
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#107b10',
                '#4f4848',
                // ... more colors as needed
            ],

        }],
    } : null;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom', // Position the legend at the bottom
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const label = context.label;
                        const bidValue = contractData.bid[label];
                        const madeValue = contractData.made[label];
                        const percentage = bidValue > 0 ? ((madeValue / bidValue) * 100).toFixed(1) : 0;
                        return `Bid:${bidValue} with ${percentage}% made`;
                    }
                }
            },
            title: {
                display: true,
                text: 'Contract Bid Summary',  // Replace with your title
                position: 'top'
            },
        },

    };

    return (
        <div className="contract-pie-chart" key={isLoading ? 'loading' : 'loaded'}  >
            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                (contractData ?
                    <Pie data={chartData} options={options}/>
                    : <p>Error loading data</p>) // Show error or chart based on data availability
            )}
        </div>
    );
}

export default ContractPerformance;