import React, {useState, useEffect, useCallback} from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from "chart.js";
import { Chart } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import '../../styles/bidTab.css'
import BidsModal from "../resultsdisplay/BidsModal";

Chart.register(CategoryScale);

function MainBidGraph({ isLoading, bidData, scoreData, days }) {
    const [chartData, setChartData] = useState({});
    const isLongTerm = days === null || days >= 90;
    const [selectedData, setSelectedData] = useState(null); // State for storing selected data
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const handleClick = (event, elements) => {
        if (elements.length > 0) {
            const index = elements[0].index;
            const selectedDayData = bidData.find(data => {
                const eventDate = new Date(data.event_date).toISOString().split('T')[0];
                return eventDate === chartData.labels[index];
            });

            setSelectedData(selectedDayData);
            setIsModalOpen(true);
        }
    };

    const calculateTrendline = (dataPoints) => {
        let sumX = 0, sumY = 0, sumXY = 0, sumXX = 0;
        const n = dataPoints.length;

        dataPoints.forEach((point, index) => {
            sumX += index;
            sumY += point;
            sumXY += index * point;
            sumXX += index * index;
        });

        const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;

        return dataPoints.map((_, index) => slope * index + intercept);
    };

    const processData = useCallback((data, scoreData) => {
        const gameDateAccuracies = {};

        data.forEach(game => {
            game.bid_data.forEach(bid => {
                if (bid.bid_accuracy !== null) {
                    const bidAccuracy = parseFloat(bid.bid_accuracy);
                    const eventDate = new Date(game.event_date).toISOString().split('T')[0]; // Stripping time part
                    const gameId = game.game_id;
                    const gameDateKey = `${eventDate}_${gameId}`;

                    if (!gameDateAccuracies[gameDateKey]) {
                        gameDateAccuracies[gameDateKey] = { total: 0, count: 0, partner: game.partner, date: eventDate };
                    }

                    gameDateAccuracies[gameDateKey].total += bidAccuracy;
                    gameDateAccuracies[gameDateKey].count++;

                }

            });
        });


        const sortedKeys = Object.keys(gameDateAccuracies).sort(); // Sort by gameDateKey
        const labels = [];
        const dataPoints = [];
        const partnersData = {};
        const scorePoints = [];

        sortedKeys.forEach(key => {
            const { total, count, partner, date } = gameDateAccuracies[key];
            labels.push(date);
            dataPoints.push(total / count);
            partnersData[key] = partner;
            const matchingScoreEntry = scoreData.find(item => new Date(item.event_date).toISOString().split('T')[0] === date);
            scorePoints.push(matchingScoreEntry ? parseFloat(matchingScoreEntry.score) : null);
        });



        const trendlineData = calculateTrendline(dataPoints);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Average Bid Accuracy',
                    data: dataPoints,
                    borderColor: 'rgb(158,70,161)',
                    borderWidth: 2,
                    tension: 0.2,
                    pointStyle: 'rectRot',
                },
                {
                    label: 'Score',
                    data: scorePoints,
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 2,
                    tension: 0.1,
                },
                {
                    label: 'Bid Accuracy Trendline',
                    data: trendlineData,
                    borderColor: 'rgba(17,17,17,0.91)',
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0, // No points for the trendline
                    tension: 0.2, // Match the tension of the original line
                }
            ],
            partners: partnersData,
        });
    }, []);

    const options = {
        onClick: handleClick,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: isLongTerm ? 'month' : 'day',
                    tooltipFormat: 'MMM d, yyyy', // Format for tooltip
                    displayFormats: {
                        day: 'MMM d, yyyy', // Format for day scale
                        month: 'MMM yyyy', // Format for month scale
                    },
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    beforeBody: function(context) {
                        const index = context[0].dataIndex;
                        const dateLabel = chartData.labels[index];
                        const matchingKeys = Object.keys(chartData.partners).filter(key => key.startsWith(dateLabel));
                        const partnerNames = matchingKeys.map(key => chartData.partners[key]);
                        return partnerNames.length > 0 ? `Partners: ${partnerNames.join(', ')}` : '';
                    },
                }
            }
        }
    };

    useEffect(() => {
        if (bidData) {
            processData(bidData, scoreData);
        }
    }, [bidData, processData, scoreData]);

    return (
        <div className="main-bid-chart">
            {isLoading ?
                <div className="loader-container">
                    <div className="loader"></div>
                </div> :
                <Line data={chartData} options={options} />
            }
            <BidsModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                filterBidData={selectedData}
            />
        </div>
    );
}

export default MainBidGraph;
