import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import '../../styles/bidTab.css'

const GoodBadBid = ({ isLoading, biddata, declarer }) => {
    const [issueCounts, setIssueCounts] = useState([]);
    const [chartData, setChartData] = useState({
        labels: ['Good', 'Okay', 'Bad'],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(255, 99, 132, 0.5)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)', 'rgba(255, 99, 132, 1)'],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        if (biddata) {
            let counts = [0, 0, 0]; // Good, Okay, Bad
            let issueCountsObj= {};

            biddata.forEach(item => {
                item.bid_data.forEach((bid) => {
                    if ((declarer && bid.direction.includes(bid.declarer)) || (!declarer && !bid.direction.includes(bid.declarer))) {
                        if (bid.bid_accuracy >= 66.6) {
                            counts[0] += 1; // Good
                        } else if (bid.bid_accuracy > 33.3) {
                            counts[1] += 1; // Okay
                        } else {
                            counts[2] += 1; // Bad
                            if (bid.bid_reason_code != null)
                            {
                                issueCountsObj[bid.bid_reason_code] = (issueCountsObj[bid.bid_reason_code] || 0) + 1;
                            }
                        }
                    }
                });
            });
            setIssueCounts(Object.entries(issueCountsObj).sort((a, b) => b[1] - a[1]));

            setChartData({
                labels: ['Good', 'Okay', 'Bad'],
                datasets: [
                    {
                        data: counts,
                        backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(255, 99, 132, 0.5)'],
                        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)', 'rgba(255, 99, 132, 1)'],
                        borderWidth: 1,
                    },
                ],
            });
        }
    }, [biddata, declarer]);

    const options = {
        plugins: {
            title: {
                display: true,
                text: declarer ? 'Declarer Bid Performance' : 'Defender Bid Performance',
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const total = context.dataset.data.reduce((a, b) => a + b, 0);
                        const value = context.raw;
                        const percentage = ((value / total) * 100).toFixed(2);
                        let label = [`${context.label}: ${value} (${percentage}%)`];
                        // If 'Bad' bid is hovered over, append the issue counts to the label
                        if (context.label === 'Bad') {
                            issueCounts.forEach(([code, count]) => {
                                label.push(`${code}: ${count}`);
                            });
                        }
                        return label;
                    }
                }
            }
        },
    };

    return (
        <div className="good-bad-bid-chart">
            {isLoading ?
                <div className="loader-container">
                    <div className="loader"></div>
                </div> :
                <Pie data={chartData} options={options}/>
            }
        </div>
    );
};

export default GoodBadBid;