// Header.js
import React, { useState, useEffect, } from 'react';
import LoginForm from './LoginForm';
import Modal from './Modal';
import axios from 'axios';
import UserSettings from './UserSettings';
import { API_BASE_URL } from "../config";
import '../styles/headerStyles.css';


function Header({ onAcblValueChange }) {
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
    const [user, setUser] = useState({ firstName: '', lastName: '' });
    const [superUser, setSuperUser] = useState(false);
    const [usersList, setUsersList] = useState([]); // stores the list of users for the superuser to select from
    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

    const handleLoginFormClose = () => {
        setIsLoginFormVisible(false);
    };

    const handleSettingsModalClose = () => {
        setIsSettingsModalVisible(false);
    };

    const handleSettingsClick = () => {
        setIsSettingsModalVisible(true);
    };

    const getCsrfToken = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('csrftoken=')) {
                return cookie.substring('csrftoken='.length, cookie.length);
            }
        }
        return '';
    }


    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/user/logcheck/`, { withCredentials: true });
                if (response.data.isAuthenticated) {
                    setUser({
                        firstName: response.data.user.first_name,
                        lastName: response.data.user.last_name
                    });
                    onAcblValueChange(response.data.user.acbl_number);
                    // Additional actions if the user is authenticated
                }
                if (response.data.user.is_superuser) {
                    setSuperUser(true);
                    fetchUsersList();
                }
                else {
                    setSuperUser(false);
                }
            } catch (error) {
                console.error('Error checking login status', error);
                // Handle errors, e.g., by setting an error state or redirecting
            }
        };
        checkLoginStatus();
    }, [onAcblValueChange]);

    const fetchUsersList = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/user/users/`, { withCredentials: true });
            setUsersList(response.data.users);
        } catch (error) {
            console.error('Error fetching users list', error);
        }
    };
    const handleUserLogin = ( first_name, last_name, acbl_number ) => {
        setUser({ firstName: first_name, lastName: last_name });
        onAcblValueChange(acbl_number);
    };

    const handleUserSelection = (acbl_number) => {
        onAcblValueChange(acbl_number);
    };
    const handleLogout = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/logout/`,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            if (response.status === 200) {
                setUser({ firstName: '', lastName: '' });
                setSuperUser(false);
                onAcblValueChange('');
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <header className="header">
            <img src="/robot_logo.png" alt="PlayPal Logo" className="header-logo" />
            <div className="header-controls">
                {superUser && (
                    <select onChange={(e) => handleUserSelection(e.target.value)}>
                        <option>Select a User</option>
                        {usersList.map(user => (
                            <option key={user.acbl_num} value={user.acbl_num}>
                                {user.name}
                            </option>
                        ))}
                    </select>
                )}
                {user.firstName && user.lastName ? (
                    <>
                        <div className="dropdown">
                            <span className="welcome-label">Welcome, {user.firstName} {user.lastName}</span>
                            <div className="dropdown-content">
                                <button onClick={handleSettingsClick}>Settings</button>
                            </div>
                        </div>
                        <button onClick={handleLogout} className="header-button">Sign Out</button>
                    </>
                ) : (
                    <button onClick={() => setIsLoginFormVisible(true)} className="header-button">
                        Login / Sign Up
                    </button>
                )}
            </div>
            {isLoginFormVisible && (
                <Modal onClose={handleLoginFormClose} className="login-modal-content">
                    <LoginForm onAcblValueChange={handleUserLogin} onClose={handleLoginFormClose} />
                </Modal>
            )}
            {isSettingsModalVisible && (
                <Modal onClose={handleSettingsModalClose} modalClass="settings-modal-content">
                    <UserSettings />
                </Modal>
            )}
        </header>
    );
}

export default Header;
