import React, { useState } from 'react';
import ModalHandDetails from "./ModalHandDetails";
import BoardDetail from "./BoardDetail";
import './handModal.css';


function FloatingToolbar({ content, position }) {
    if (!content) return null;

    return (
        <div className="toolbar" style={{ position: 'fixed', left: position.x, top: position.y }}>
            {content}
        </div>
    );
}

function TableHeaders() {
    return (
        <thead className="modal-hand-details-header">
        <tr>
            <th>Board Num</th>
            <th>Direction</th>
            <th>Declarer</th>
            <th>Contract</th>
            <th>Result</th>
            <th>Score</th>
            <th>Bid Accuracy</th>
            <th>Bid Reason Code</th>
        </tr>
        </thead>
    );
}

function BidsModal({ isOpen, onRequestClose, filterBidData }) {
    const [toolbarContent, setToolbarContent] = useState(null);
    const [toolbarPosition, setToolbarPosition] = useState({x: 0, y: 0});

    if (!isOpen) return null;

    const sortedBidData = filterBidData.bid_data.sort((a, b) => a.board_num - b.board_num);

    // Function to stop the propagation of click events inside the modal content
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleMouseEvents = (bid, position = null, isEntering = false) => {
        if (isEntering) {
            setToolbarPosition(position);
            // Set the content you want to display in the toolbar
            setToolbarContent(<BoardDetail boardId={bid.board_id} declarer={bid.declarer}/>);
        } else {
            setToolbarContent(null);
        }
    };

return (
    <div className="modal-backdrop" onClick={onRequestClose}>
        <FloatingToolbar content={toolbarContent} position={toolbarPosition} />
        <div className="modal" onClick={stopPropagation}>
            <h2>Bid Data Details</h2>
            <p>Date: {filterBidData.event_date}</p>
            <p>Partner: {filterBidData.partner}</p>
            <div className="modal-hand-details-container">
                <table style={{width: '100%', textAlign: 'left'}}>
                    <TableHeaders />
                    <tbody>
                    {sortedBidData.map((bid, index) => (
                        <ModalHandDetails key={index} bid={bid} onMouseEvent={handleMouseEvents}/>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);
}
export default BidsModal;