import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import { API_BASE_URL } from "../../config";
import '../../styles/bidTab.css';

const PointsSummary = ({ acblValue, partnerAcbl, timeFrame }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [pointSummary, setPointSummary] = useState({ NT: [], Majors: [], Minors: [] });
    const [toggle, setToggle] = useState('hcp'); // hcp or tcp


    useEffect(() => {
        setIsLoading(true);
        let pointurl = `${API_BASE_URL}/api/bid/hcp/${acblValue}/`
        if (partnerAcbl !== null) {
            pointurl += `${partnerAcbl}/`;
        }
        if (timeFrame !== null) {
            pointurl += `?time_delta=${timeFrame}`;
        }
        axios.get(pointurl, { withCredentials: true })
            .then(response => {
                setPointSummary(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [timeFrame, acblValue, partnerAcbl]);

    const processData = (data) => {
        const processedData = {
            'NT': [],
            'Majors': [],
            'Minors': []
        };

        const ranges = {
            'NT': { start: 23, end: 27 },
            'Major': { start: 23, end: 27 },
            'Minor': { start: 25, end: 30 }
        };

        const displayTitles = {
            'NT': 'NT',
            'Major': 'Majors',
            'Minor': 'Minors'
        };

        const pointProperty = toggle; // 'hcp' or 'tcp'

        // Initialize all points with a default total and success rate of 0
        for (let key in ranges) {
            if (ranges.hasOwnProperty(key)) {
                const displayKey = displayTitles[key];
                for (let i = ranges[key].start; i <= ranges[key].end; i++) {
                    processedData[displayKey].push({
                        point: i,
                        successRate: 0,
                        total: 0
                    });
                }
            }
        }

        // Update the totals and success rates based on the data
        for (let key in data) {
            if (data.hasOwnProperty(key) && ranges.hasOwnProperty(key)) {
                const pointData = data[key];
                const displayKey = displayTitles[key];
                for (let item of pointData) {
                    const pointIndex = item[pointProperty] - ranges[key].start;
                    if (pointIndex >= 0 && pointIndex < processedData[displayKey].length) {
                        processedData[displayKey][pointIndex].total++;
                        if (item.success) {
                            processedData[displayKey][pointIndex].successRate++;
                        }
                    }
                }

                // Calculate the success rate
                for (let i = 0; i < processedData[displayKey].length; i++) {
                    if (processedData[displayKey][i].total > 0) {
                        processedData[displayKey][i].successRate /= processedData[displayKey][i].total;
                    }
                }
            }
        }

        return processedData;
    };







    const chartData = processData(pointSummary);
    const handleToggleChange = (newToggle) => {
        setToggle(newToggle);
    };
    const generateOptions = (type) => ({
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += Math.round(context.parsed.y * 100) + '%';
                        label += ' of ' + chartData[type][context.dataIndex].total + ' bids';
                        return label;
                    }
                }
            }
        }
    });


    return (
        <div>

            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <div>
                        <input
                            type="range"
                            min="0"
                            max="1"
                            value={toggle === 'hcp' ? 0 : 1}
                            onChange={(e) => handleToggleChange(e.target.value === '0' ? 'hcp' : 'tp')}
                        />
                        <span>{toggle === 'hcp' ? 'HCP' : 'Total Points'}</span>
                    </div>
                    <div className="point-chart-container">
                        <div className="point-bar-chart">
                            <Bar data={{
                                labels: chartData['NT'].map(item => item.point),
                                datasets: [{
                                    label: '3NT',
                                    data: chartData['NT'].map(item => item.successRate),
                                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                                    borderColor: 'rgba(75, 192, 192, 1)',
                                    borderWidth: 1
                                }]
                            }} options={generateOptions('NT')}/></div>
                        <div className="point-bar-chart">
                            <Bar data={{
                                labels: chartData['Majors'].map(item => item.point),
                                datasets: [{
                                    label: 'Majors',
                                    data: chartData['Majors'].map(item => item.successRate),
                                    backgroundColor: 'rgba(255, 159, 64, 0.5)',
                                    borderColor: 'rgba(255, 159, 64, 1)',
                                    borderWidth: 1
                                }]
                            }} options={generateOptions('Majors')}/>
                        </div>
                        <div className="point-bar-chart">
                            <Bar data={{
                                labels: chartData['Minors'].map(item => item.point),
                                datasets: [{
                                    label: 'Minors',
                                    data: chartData['Minors'].map(item => item.successRate),
                                    backgroundColor: 'rgba(153, 102, 255, 0.5)',
                                    borderColor: 'rgba(153, 102, 255, 1)',
                                    borderWidth: 1
                                }]
                            }} options={generateOptions('Minors')}/>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PointsSummary;