// LandingPage.js
import React from 'react';
import '../styles/landingPage.css'; // Importing the CSS file

function LandingPage() {
    return (
        <div className="landing-page">
            <header className="landing-header">
                <h1>Bridge Play Pal</h1>
                <p>Analyze Your Duplicate Play</p>
            </header>

            <main className="landing-main">
                <h2>What We Do</h2>
                <p>A website with your ACBL club hands and some useful dashboards and analysis to review different fascists of your game and identify trends</p>
                <p>This is a demo site and is currently only working for clubs in the Calgary region, I'm not opposed to adding more</p>
                <br></br>
                <p>If you you are getting use of this please feel free to buy me a coffee</p>
                <a href="https://www.paypal.com/donate/?business=SDPXFXCXT5H5Y&no_recurring=0&currency_code=CAD" target="_blank" rel="noreferrer">
                    <button>Donate</button>
                </a>
            </main>

            <div className="features-section">
                <div className="feature">
                    <h3>Bid</h3>
                    <p>See how you and your partner have bid in your hands relative to double dummy and identify potential weaknesses and patterns to improve</p>
                </div>
                <div className="feature">
                    <h3>Play</h3>
                    <p>Once you made the contract how did you play it, does require some information on where you have sat, particularly for Howell movements.</p>
                </div>
                <div className="feature">
                    <h3>Opponents</h3>
                    <p>Are there particular opponents that are consistently giving you issues and the same issues, this groups those plays together so you can better identify patterns</p>
                </div>
            </div>

            <footer className="landing-footer">
                <p>&copy; {new Date().getFullYear()} Bridge Play Pal</p>
            </footer>
        </div>
    );
}

export default LandingPage;
