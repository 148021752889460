import React, { useState, useEffect } from 'react';
import axios from "axios";
import MainBidGraph from "./bid/MainBidGraph";
import { API_BASE_URL } from "../config";
import ContractBidPerformance from "./bid/ContractBidPerformance";
import '../styles/bidTab.css';
import GameTypeBidPerformance from "./bid/GameTypeBidPerformance";
import GoodBadBid from "./bid/GoodBadBid";
import PointsSummary from "./bid/PointsSummary";

function BidTab({ timeFrame, acblValue, partnerAcbl }) {
    const [isLoading, setIsLoading] = useState(true);
    const [bidData, setBidData] = useState([]);
    const [scoreData, setScoreData] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        let bidurl = `${API_BASE_URL}/api/bid/${acblValue}/`;
        let scoreurl = `${API_BASE_URL}/api/total/results/${acblValue}/`;
        if (partnerAcbl !== null) {
            bidurl += `${partnerAcbl}/`;
            scoreurl += `${partnerAcbl}/`;
        }
        if (timeFrame !== null) {
            bidurl += `?time_delta=${timeFrame}`;
            scoreurl += `?time_delta=${timeFrame}`;
        }
        axios.all([
            axios.get(bidurl, {withCredentials: true}),
            axios.get(scoreurl, {withCredentials: true})
        ])
            .then(axios.spread((bidResponse, scoreResponse) => {
                setBidData(bidResponse.data.bid_accuracy_overview);
                setScoreData(scoreResponse.data.overall_performance);
            }))
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [timeFrame, acblValue, partnerAcbl]);

    const handleButtonClick = () => {
        setButtonClicked(true);
    }

    return (
        <div className="bid-container">
            <div className="main-bid-graph">
                <MainBidGraph
                    isLoading={isLoading}
                    bidData={bidData}
                    scoreData={scoreData}
                    days={timeFrame}
                />
            </div>
            <div className="bid-performance-container">
                <div className="contract-bid-performance">
                    <ContractBidPerformance
                        isLoading={isLoading}
                        biddata={bidData}
                    />
                </div>
                <div className="type-bid-performance">
                    <GameTypeBidPerformance
                        isLoading={isLoading}
                        biddata={bidData}
                    />
                </div>
            </div>
            <div className="bid-performance-container">
                <div className="declarer-bid-performance">
                    <GoodBadBid
                        isLoading={isLoading}
                        biddata={bidData}
                        declarer={true}
                    />
                </div>
                <div className="defender-bid-performance">
                    <GoodBadBid
                        isLoading={isLoading}
                        biddata={bidData}
                        declarer={false}
                    />
                </div>
            </div>
            <div className="point-summary">
                <h2>Points Summary</h2>
                <p>Requires a lot of processing so it is run seperately</p>
                <button onClick={handleButtonClick}>Run Query</button>
                {buttonClicked &&
                    <PointsSummary
                        acblValue={acblValue}
                        partnerAcbl={partnerAcbl}
                        timeFrame={timeFrame}
                    />
                }
            </div>
        </div>
    );
}
export default BidTab;