import React, { useState, useEffect } from 'react';
import ScorePerDay from "./maindash/ScorePerDay";
import ContractPerformance from "./maindash/ContractPerformance";
import RolePerformance from "./maindash/RolePerformance";
import OverUnderTricks from "./maindash/OverUnderTricks";
import RankingPerformance from "./maindash/RankingPerformance";
import { API_BASE_URL} from "../config";
import axios from "axios";


function FrontPage({ timeFrame, acblValue, partnerAcbl}) {
    const [rolesData, setRolesData] = useState(null);
    const [isRolePerformanceLoading, setIsRolePerformanceLoading] = useState(true);
    // run the role data in the frontpage to avoid multiple calls for the elements
    useEffect(() => {
        setIsRolePerformanceLoading(true)
        let url = `${API_BASE_URL}/api/total/roles/${acblValue}/`;
        if (partnerAcbl !== null) {
            url += `${partnerAcbl}/`;
        }
        if (timeFrame !== null) {
            url += `?time_delta=${timeFrame}`;
        }

        axios.get(url, { withCredentials: true })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setRolesData(response.data);
                setIsRolePerformanceLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                setIsRolePerformanceLoading(false)
            });

    }, [timeFrame, acblValue, partnerAcbl]); // Dependency array includes timeFrame


    return (
        <div className="frontpage-container">
            <RolePerformance query={rolesData} isLoading={isRolePerformanceLoading}/>
            <ScorePerDay days={timeFrame} acbl={acblValue} partAcbl={partnerAcbl} />
            <div className="performance-container">
                <div className="performance-title">
                    <ContractPerformance days={timeFrame} acbl={acblValue} partAcbl={partnerAcbl}/>
                </div>
                <div className="performance-title">
                    <OverUnderTricks query={rolesData} isLoading={isRolePerformanceLoading}/>
                </div>
            </div>
            <RankingPerformance acbl={acblValue} />
            {/* ... other content ... */}
        </div>
    );
}

export default FrontPage;