// LoginForm.js
import React, { useState } from 'react';
import { API_BASE_URL} from "../config";
import axios from 'axios';

function LoginForm( {onAcblValueChange , onClose} ) {

    const [isRegisterMode, setIsRegisterMode] = useState(false);
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [acbl_number, setAcblNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const getCsrfToken = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('csrftoken=')) {
                return cookie.substring('csrftoken='.length, cookie.length);
            }
        }
        return '';
    }
    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setErrorMessage('Invalid email address');
            return;
        }

        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/login/`,
                data: { email, password },
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            const { first_name, last_name, acbl_number } = response.data;
            onAcblValueChange(first_name, last_name, acbl_number);
            onClose();
        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data.message);
        }
    };
    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault(); // Prevent form from being submitted twice
            isRegisterMode ? handleRegister(event) : handleLogin(event);
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        if (!validateEmail(email)) {
            setErrorMessage('Invalid email address');
            return;
        }

        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/register/`,
                data: { email, first_name, last_name, acbl_number, password },
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            if (response.status === 201) {
                window.alert('User Created');
                onClose();
            }
        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data.message);
        }
    };

    return (
        <div className="form-container">
        <form onSubmit={isRegisterMode ? handleRegister : handleLogin}>
            <div className="form-fields">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Email"
                />
                {isRegisterMode && (
                    <>
                        <input
                            type="text"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="First Name"
                        />
                        <input
                            type="text"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Last Name"
                        />
                        <input
                            type="text"
                            value={acbl_number}
                            onChange={(e) => setAcblNumber(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="ACBL Number"
                        />
                    </>
                )}
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Password"
                />
                {isRegisterMode && (
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Confirm Password"
                    />
                )}
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="form-buttons">
                <button type="submit">{isRegisterMode ? 'Register' : 'Login'}</button>
                <button type="button" onClick={() => {
                    setIsRegisterMode(!isRegisterMode);
                    setErrorMessage('');
                    setEmail('');
                    setPassword('');
                }}>
                    Switch to {isRegisterMode ? 'Login' : 'Register'}
                </button>
            </div>
        </form>
        </div>
    );
}

export default LoginForm;