import React, { useState, useEffect } from 'react';

function RolePerformance({ query, isLoading }) {
    const [rolesPerformData, setRolesPerformData] = useState(null);
    
    useEffect(() => {
        if (!isLoading && query && query.role_performance) {
            setRolesPerformData(query.role_performance);
        }
    }, [query, isLoading]);
    const renderRoleData = () => {
        if (rolesPerformData) {
            return (
                <div className="row">
                    {Object.entries(rolesPerformData).map(([role, performance]) => (
                        <div key={role} className="cell">
                            <div className="role-name">{role}</div>
                            <div className="role-performance">{performance}%</div>
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };

    if (isLoading) {
        return(
            <div className="roles-performance" key={isLoading ? 'loading' : 'loaded'} >
                {isLoading ?
                    <div className="loader"></div> :  // Use the loader class for the spinning animation
                    null
                }
            </div>
        );
    }


    return (
        <div className="roles-performance" key={'loaded'}>
            {renderRoleData()}
        </div>
    );

}   

export default RolePerformance;