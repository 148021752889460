import React, { useState, useEffect } from 'react';
import { API_BASE_URL} from "../../config";
import axios from "axios";

function RankingPerformance({ acbl }) {
    const [rankingData, setRankingData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        let url = `${API_BASE_URL}/api/total/ranking/${acbl}/`;
        axios.get(url, {withCredentials: true})
            .then(response => {
                console.log("API Response:", response.data);
                setRankingData(response.data.rankings);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                setIsLoading(false);
            });

    }, [acbl]); // Dependency array includes timeFrame

    const calculatePercentile = (rank, total) => ((1 - (rank / total)) * 100).toFixed(2);

    const renderTable = () => {
        if (!rankingData || rankingData.length === 0) return null;

        return (
            <table>
                <thead>
                <tr>
                    <th>Partner Name</th>
                    <th>Overall (%)</th>
                    <th>Games (%)</th>
                    <th>Unit (%)</th>
                    <th>Strata (%)</th>
                </tr>
                </thead>
                <tbody>
                {rankingData.map(partner => (
                    <tr key={partner.partner_name}>
                        <td>{partner.partner_name}</td>
                        <td>{calculatePercentile(partner.ranks.overall[0], partner.ranks.overall[1])}</td>
                        <td>{calculatePercentile(partner.ranks.games[0], partner.ranks.games[1])}</td>
                        <td>{calculatePercentile(partner.ranks.unit[0], partner.ranks.unit[1])}</td>
                        <td>{calculatePercentile(partner.ranks.strata[0], partner.ranks.strata[1])}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (rankingData && rankingData.length > 0) {
        return (
            <div>
                <h3>Partner Rankings</h3>
                {renderTable()}
            </div>
        );
    }
    return null;
}

export default RankingPerformance;

