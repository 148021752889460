import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../../config";
import './handModal.css';
function BoardDetail({ boardId, declarer }) {
    const [bidDetails, setBidDetails] = useState(null);

    useEffect(() => {
        const fetchBidDetails = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/board-details/${boardId}`, { withCredentials: true });
                setBidDetails(response.data);
            } catch (error) {
                console.error('Error fetching bid details:', error);
            }
        };

        if (boardId) {
            fetchBidDetails();
        }
    }, [boardId]);


    const renderHand = (direction) => {
        const hand = bidDetails.hand_records.find(record => record.direction === direction);
        if (!hand) return null;

        // handles the highlighting of the declarer
        const handClass = `compass-direction ${direction} ${declarer === direction ? 'highlight' : ''}`;

        return (
            <div className={handClass}>
                <p style={{color: 'black'}}>&spades;: {hand.spades}</p>
                <p style={{color: 'red'}}>&hearts;: {hand.hearts}</p>
                <p style={{color: 'red'}}>&diams;: {hand.diamonds}</p>
                <p style={{color: 'black'}}>&clubs;: {hand.clubs}</p>
            </div>
        );
    };

    const renderDoubleDummy = (doubleDummy) => {
        const contracts = doubleDummy.split(' ');
        return contracts.slice(1).map((contract) => {
            const isLetter = isNaN(parseInt(contract[0]));
            return `<span class="${isLetter ? 'highlight-contract' : ''}">${contract}</span>`;
        }).join(' ');
    };


    if (!bidDetails) return <div>Loading...</div>;

    return (
        <div className="compass-layout">
            <div className="board-info">
                <p>Board: {bidDetails.double_dummy_data.board}</p>
                <p>Vul: {bidDetails.double_dummy_data.vulnerability}</p>
            </div>
            N:
            <div className="compass-direction north">{renderHand('N')}</div>
            <div className="compass-center">
                W:
                <div className="compass-direction west">{renderHand('W')}</div>
                E:
                <div className="compass-direction east">{renderHand('E')}</div>
            </div>
            S:
            <div className="compass-direction south">{renderHand('S')}</div>
            <div className="double-dummy">
                <p>Double Dummy:</p>
                <p>NS: <span
                    dangerouslySetInnerHTML={{__html: renderDoubleDummy(bidDetails.double_dummy_data.double_dummy_ns)}}/>
                </p>
                <p>EW: <span
                    dangerouslySetInnerHTML={{__html: renderDoubleDummy(bidDetails.double_dummy_data.double_dummy_ew)}}/>
                </p>
            </div>
        </div>
    );
}

export default BoardDetail;