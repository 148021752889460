// Modal.js
import React from 'react';

function Modal({ children, onClose, modalClass }) {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className={modalClass} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
}

export default Modal;