// src/components/AccountActivation.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from "./config";

function AccountActivation() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [isActivated, setIsActivated] = useState(false);

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axios.put(`${API_BASE_URL}/user/account/activate/?token=${token}`);
                console.log(response.data);
                setIsActivated(true)
                // Handle successful activation (e.g., redirect to login page, show success message)
            } catch (error) {
                console.error('Error activating account', error);
                // Handle failed activation (e.g., show error message)
            }
        };
        activateAccount();
    }, [token]);

    return (
        <div>
            {/* You can add some loading text or a spinner here */}
            {isActivated ? <p>User activated</p> : <p>Activating account...</p>}
        </div>
    );
}

export default AccountActivation;