import React, { useState, useEffect } from 'react';
import './handModal.css';




function ModalHandDetails({ bid, onMouseEvent }) {
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const toolbarHeight = 200;

    const handleMouseEnter = (e) => {
        const timeout = setTimeout(() => {
            const midpoint = window.innerHeight / 2;
            const top = e.clientY > midpoint ? e.clientY - toolbarHeight : e.clientY;
            onMouseEvent(bid, {x: e.clientX, y: top}, true);
        }, 2000);
        setHoverTimeout(timeout);
    };

    const handleMouseLeave = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
        onMouseEvent(bid, null, false);
    };


    useEffect(() => {
        return () => {
            if (hoverTimeout) {
                clearTimeout(hoverTimeout);
            }
        };
    }, [hoverTimeout]);
    const formatBidAccuracy = (accuracy) => {
        return accuracy ? `${accuracy}%` : '';
    };

    // Function to determine the color based on bid_accuracy
    const getAccuracyColor = (accuracy) => {
        if (!accuracy) return 'black';
        const numAccuracy = parseFloat(accuracy);
        if (numAccuracy > 66.6) return 'green';
        if (numAccuracy < 33.3) return 'red';
        return 'black';
    };

    return (
        <tr className="modal-board-grouping" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <td>{bid.board_num}</td>
            <td>{bid.direction}</td>
            <td>{bid.declarer}</td>
            <td>{bid.contract}</td>
            <td>{bid.result}</td>
            <td style={{color: bid.score > 0 ? 'green' : 'red'}}>{bid.score}</td>
            <td style={{color: getAccuracyColor(bid.bid_accuracy)}}>{formatBidAccuracy(bid.bid_accuracy)}</td>
            <td>{bid.bid_reason_code || ''}</td>
        </tr>
    );
}

export default ModalHandDetails;
