// UserSettings.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../config";
import '../styles/userSettings.css';

function UserSettings() {
    const [settings, setSettings] = useState([]);
    const [filteredSettings, setFilteredSettings] = useState([]);
    const [showAll, setShowAll] = useState(false); // New state variable
    const [errorMessage, setErrorMessage] = useState('');

    const getCsrfToken = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('csrftoken=')) {
                return cookie.substring('csrftoken='.length, cookie.length);
            }
        }
        return '';
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/user/settings/`, {withCredentials: true});
                setSettings(response.data);
                const filtered = response.data.filter(setting => setting.orientation === null);
                setFilteredSettings(filtered);
            } catch (error) {
                console.error('Error fetching user settings', error);
            }
        };
        fetchSettings();
    }, []);

    const handleFilter = () => {
        if (showAll) {
            const filtered = settings.filter(setting => setting.orientation === null);
            setFilteredSettings(filtered);
        } else {
            setFilteredSettings(settings);
        }
        setShowAll(!showAll); // Toggle the showAll state variable
    };

    const handleUpdate = async (pair_id, selectedPosition) => {
        try {
            await axios.put(`${API_BASE_URL}/user/settings/`, {
                pair_id: pair_id,
                position: selectedPosition
            }, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
        } catch (error) {
            console.error('Error updating user setting', error);
            setErrorMessage('Error updating user setting');
        }
    };

    return (
        <div>
            <button onClick={handleFilter}>{showAll ? 'Filter Blanks' : 'Show All'}</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {filteredSettings.map(setting => (
                <div className='settings-group' key={setting.pair_id}>
                    <p>{setting.club_name}</p>
                    <p>{setting.game_id}</p>
                    <p>Date: {setting.game_date}</p>
                    <p>Partner Name: {setting.partner_name}</p>
                    {setting.pair_position === 'both' && (
                        <div>
                            <input type="radio" id="NE" name={setting.pair_id} value="NE"
                                   defaultChecked={setting.move_type === 'NE'}/>
                            <label htmlFor="NE">NE</label>
                            <input type="radio" id="SE" name={setting.pair_id} value="SE"
                                   defaultChecked={setting.move_type === 'SE'}/>
                            <label htmlFor="SE">SE</label>
                            <input type="radio" id="NW" name={setting.pair_id} value="NW"
                                   defaultChecked={setting.move_type === 'NW'}/>
                            <label htmlFor="NW">NW</label>
                            <input type="radio" id="SW" name={setting.pair_id} value="SW"
                                   defaultChecked={setting.move_type === 'SW'}/>
                            <label htmlFor="SW">SW</label>
                        </div>
                    )}
                    {setting.pair_position === 'NS' && (
                        <div>
                            <input type="radio" id="N" name={setting.pair_id} value="N"
                                   defaultChecked={setting.move_type === 'N'}/>
                            <label htmlFor="N">N</label>
                            <input type="radio" id="S" name={setting.pair_id} value="S"
                                   defaultChecked={setting.move_type === 'S'}/>
                            <label htmlFor="S">S</label>
                        </div>
                    )}
                    {setting.pair_position === 'EW' && (
                        <div>
                            <input type="radio" id="E" name={setting.pair_id} value="E"
                                   defaultChecked={setting.move_type === 'E'}/>
                            <label htmlFor="E">E</label>
                            <input type="radio" id="W" name={setting.pair_id} value="W"
                                   defaultChecked={setting.move_type === 'W'}/>
                            <label htmlFor="W">W</label>
                        </div>
                    )}
                    <button
                        onClick={() => handleUpdate(setting.pair_id, document.querySelector(`input[name="${setting.pair_id}"]:checked`).value)}>Update
                    </button>
                </div>
            ))}
        </div>
    );
}

export default UserSettings;