import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from "chart.js";
import { Chart } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns'
import { API_BASE_URL} from "../../config";

Chart.register(CategoryScale, annotationPlugin);

function ScorePerDay({ days, acbl, partAcbl }) {
    const [performanceData, setPerformanceData] = useState([]);

    // Function to handle time frame changes

    useEffect(() => {
        let url = `${API_BASE_URL}/api/total/results/${acbl}/`
        if (partAcbl !== null) {
            url += `${partAcbl}/`;
        }
        if (days !== null) {
            url += `?time_delta=${days}`;
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setPerformanceData(data.overall_performance);
            })
            .catch(error => console.error('Error fetching data:', error));

    }, [days, acbl, partAcbl]);

    const isLongTerm = days === null || days >= 90;

    const dataMin = Math.min(...performanceData.map(item => parseFloat(item.score)));
    const adjustedMin = Math.round(dataMin - 5);
    const formatEventDate = (dateString) => {
        // Assuming dateString is in a format that can be parsed by the Date constructor
        return new Date(dateString).toISOString().split('T')[0];
    };
    const calculateTrendline = (dataPoints) => {
        let sumX = 0, sumY = 0, sumXY = 0, sumXX = 0;
        const n = dataPoints.length;

        dataPoints.forEach((point, index) => {
            sumX += index;
            sumY += point;
            sumXY += index * point;
            sumXX += index * index;
        });

        const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;

        return dataPoints.map((_, index) => slope * index + intercept);
    };
    let trendlineData = [];
    if (performanceData.length > 0) {
        const dataPoints = performanceData.map(item => parseFloat(item.score));  // Y-axis data
        trendlineData = calculateTrendline(dataPoints);
    }

    const data = {
        labels: performanceData.map(item => formatEventDate(item.event_date)),  // X-axis labels
        datasets: [{
            label: 'Game Performance',
            data: performanceData.map(item => parseFloat(item.score)),  // Y-axis data
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.1,
        },
        {
            label: 'Trendline',
            data: trendlineData,
            borderColor: 'rgba(17,17,17,0.91)',
            borderWidth: 2,
            fill: false,
            pointRadius: 0, // No points for the trendline
            tension: 0.1, // Match the tension of the original line
        }],
    };



    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: isLongTerm ? 'month' : 'day', // Use 'month' for 3+ months, 'day' otherwise
                    tooltipFormat: 'MMM d, yyyy',
                    displayFormats: {
                        day: 'MMM d, yyyy', // Format for days
                        month: 'MMM yyyy',  // Format for months
                    },
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                min: adjustedMin,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    beforeBody: function(context) {
                        // Get the partner name for the current item
                        const partnerName = performanceData[context[0].dataIndex].partner;
                        return `Partner: ${partnerName}`;
                    },
                    label: function(context) {
                        // Extract the necessary information
                        const label = context.dataset.label || '';
                        const value = context.parsed.y.toFixed(2);
                        return `${label}: ${value}`;
                    }
                }
            },
            annotation: {
                annotations: {
                    lineAt50: {  // Arbitrary ID for the annotation
                        type: 'line',
                        yMin: 50,
                        yMax: 50,
                        borderColor: 'red',
                        borderWidth: 1,
                        borderDash: [6, 6],  // Creates a dotted line
                    },
                    boxBelow50: {
                        type: 'box',
                        yMin: adjustedMin,
                        yMax: 50,
                        backgroundColor: 'rgba(255, 0, 0, 0.1)'  // Slightly red shade
                    }
                }
            },
            title: {
                display: true,
                text: 'Performance Summary',  // Replace with your title
                position: 'top'
            },
        },
        // ... other options
    };

    return (
        <div className='total-score'>
            <Line data={data} options={options} />
        </div>
    );
}

export default ScorePerDay;
